import { Grid, Typography, FormControlLabel } from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {
  getParams,
  navigate,
  guardRoute,
  sendGetRequest,
  sendPostRequest,
  sendPatchRequest,
  getFolderName,
  getDisplayFolderName,
  toTzTime,
  removeEmpty
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";
import {
  Dropzone, FileMosaic, FullScreen,
  ImagePreview,
  VideoPreview
} from "@files-ui/react";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Autocomplete,
  TextField,
  InputAdornment,
  IconButton,
  Icon,
  DialogContent,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Breadcrumbs,
  Link,
  Chip,
  FormHelperText,
  CircularProgress,
  Switch,
  Checkbox
} from "@mui/material";
import { coreStyles } from "../theme/style";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const ageGroup = [
  { range: "0-9", name: "Early child hood (AK,NN)" },
  { range: "7-14", name: "Primary (UK,NN)" },
  { range: "18-100", name: "Caregivers" },
]


const _ref = {
  uuid: "",
  section: "",
  thumbnail: "",
  order: "",
  title: "",
  subTitle: "",
  category: "",
  forTheApp: false,
  forYoutube: null,
  language: "eng",
  fromAge: null,
  toAge: null,
  premium: [],
  source: "s3",
  scheduledAt: null,
  location: null,
  logoRef: null,
  withIntroOutro: null,
  streamQuality: ["240", "360", "480"]
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));



const ContentEdit = () => {
  const appStyle = coreStyles();
  guardRoute(false, true);
  const { uuid } = getParams();
  var [content, setContent] = useState(_ref);
  var [sections, setSections] = useState([]);
  var [categories, setCategories] = useState([]);
  var [date, setDate] = useState(new Date());
  var [urlError, setUrlError] = useState(false);
  var [thumbError, setThumbError] = useState(false);
  var [files, setFiles] = useState([]);
  const [channels, setChannels] = useState([]);
  const [tvShows, setTvShows] = useState([]);
  const [continents, setContinents] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [imageSrc, setImageSrc] = useState(undefined);
  const [videoSrc, setVideoSrc] = useState(undefined);
  const [directories, setDirectories] = useState({});
  const [buckets, setBuckets] = useState([]);
  const [actives, setActives] = useState([]);
  const [navTrack, setNavTrack] = useState([]);
  const [browse, startBrowse] = useState(false);
  const [newFolder, setNewFolder] = useState("");
  const [schedule, setSchedule] = useState(false);
  const [btnLabel, setButtonLabel] = useState("Auto Detect");
  const [onDetecting, setOnDetecting] = useState(false);
  const [detectUrl, setDetectUrl] = useState("");
  const [ageRange, setAgeRange] = useState([]);

  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });

  const getAgeRanges = (start, end) => {
    const ranges = ageGroup.map(group => {
      const parts = group.range.split("-");
      return { start: +parts[0], end: +parts[1] }
    });
    return ranges.filter(range => range.start >= start && range.end <= end).map(range => `${range.start}-${range.end}`);
  }

  const getSingleRange = (values = null) => {
    const parts = (values || ageRange).map(range => range.split("-")).flat(1);
    return `${Math.min(...parts)}-${Math.max(...parts)}`
  }

  const updateFiles = (selectedFiles) => {
    const images = [], videos = [], docs = [], audio = [];
    selectedFiles.forEach(file => {
      if (file.type.startsWith("image")) images.push(file);
      if (file.type.startsWith("video")) videos.push(file);
      if (file.type.startsWith("application")) docs.push(file);
      if (file.type.startsWith("audio")) docs.push(file);
    });

    if (images.length === 1 && (videos.length === 1 || docs.length === 1 || audio.length === 1)) {
      setFiles(selectedFiles);
    }
  };

  const handleNavigate = (nav, top) => {
    if (status.loading) return;
    if (nav === "/") navTrack.splice(0, navTrack.length);
    if (!navTrack.includes(nav) && nav !== "/") navTrack.push(nav);

    const indexTrack = navTrack.indexOf(nav);
    if (indexTrack === -1) navTrack.splice(0, navTrack.length)
    if (indexTrack !== -1 && top) navTrack.splice(indexTrack + 1, navTrack.length)
    setNavTrack(navTrack);
    let _path = navTrack.slice(1, navTrack.length).join("/");
    _path = `${_path.length === 0 ? navTrack[0] ? navTrack[0] : "" : _path}/`;
    setNewFolder("");
    const dirsToShow = _path === "/" ? buckets.map(buck => `${buck.name}/`) : directories[_path];
    setActives(dirsToShow);
  }

  const isBucketSeletions = () => navTrack.length === 0;

  const onDelete = (id) => {
    setFiles(files.filter((_file) => _file.id !== id));
  };

  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };
  const handleWatch = (videoSource) => {
    setVideoSrc(videoSource);
  };


  const validateYoutubeProps = () => {
    if (content.forYoutube === null) return true;
    return content.forYoutube !== null && content.forYoutube.channel
      && content.forYoutube.fade;
  }

  const getMimeType = () => {
    try {
      const mimeTypes = ["image/*"]
      const name = `${categories.filter(cat => cat.uuid === content.category)[0].name}`;
      if (name.toLowerCase().startsWith("video")) mimeTypes.push("video/*");
      if (name.toLowerCase().startsWith("audio")) mimeTypes.push("audio/*");
      if (name.toLowerCase().startsWith("books")) mimeTypes.push("application/pdf", "application/x-pdf");
      return mimeTypes;
    } catch (e) {
      return [];
    }
  }

  const checkLogoChennelError = () => {
    return content.forYoutube !== null
      && content.logoRef
      && content.logoRef !== content.forYoutube.channel;
  }



  useEffect(() => {
    setStatus({ ...{ loading: true } })
    Promise.all([
      sendGetRequest('categories'),
      sendGetRequest('sections'),
      sendGetRequest(`channels`),
      sendGetRequest(`contents/continents`),
      sendGetRequest(`languages`),
      sendGetRequest(`directories`),
      sendGetRequest(`buckets`),
    ]).then(result => {
      setContinents(result[3])
      setLanguages(result[4])
      setDirectories(result[5])
      setBuckets(result[6])
      const types = result[0].filter(type => type.name !== "Home");
      types.sort((prev, next) => prev.order - next.order);
      setTvShows(result[2]);
      const _channels = result[2].filter(chan => !chan.expired && chan.token);
      setChannels(_channels);
      sendGetRequest(`contents?uuid=${uuid}`,
        (loading) => setStatus({ ...{ loading: loading } }),
        (data) => {
          if (data && data.forYoutube !== null) {
            const _channel = _channels.filter(chan => chan.uuid === data.forYoutube.channel);
            if (_channel.length === 0) {
              data.forYoutube = null;
            }
          }
          setCategories(types);
          setSections(result[1]);
          if (data) {
            const _category = uuid && data.section !== null && data.section.category ? data.section.category.uuid : categories[0] ? categories[0].uuid : null;
            const _section = uuid && data.section !== null ? data.section.uuid : categories.filter(cat => cat.uuid === _category)[0].uuid || null;
            const _content = {
              ..._ref,
              ...data,
              section: _section,
              category: _category,
              source: "s3",
              withIntroOutro: data.withIntroOutro ? data.withIntroOut : null
            };
            setDate(_content.scheduledAt ? new Date(_content.scheduledAt) : new Date());
            const ranges = getAgeRanges(_content.fromAge, _content.toAge);
            setAgeRange(ranges);
            const processedRange = getSingleRange(ranges).split("-");
            if (_content.logoRef === 'null') _content.logoRef = null;
            setContent({ ..._content, fromAge: processedRange[0], toAge: processedRange[1] });
          }
        })
    });
    return () => setContent(_ref);
    // eslint-disable-next-line
  }, [uuid]);

  const noBranding = () => content.logoRef == null || content.logoRef === 'null' || content.logoRef === "none";

  const isVideo = () => content.category === "1b367a19-9bcd-4b85-92e5-9bc80e835b59";

  const getContent = () => {
    content.isVideo = isVideo();
    if (!isVideo()) {
      delete content.streamQuality;
      delete content.withIntroOutro;
    }
    delete content.category;
    if (!content.order.length) delete content.order;

    if (!schedule) delete content.scheduledAt
    if (content.location) content.location = getPath();
    if (noBranding()) content.logoRef = null;
    if (!content.streamQuality && isVideo()) content.streamQuality = ["240", "360", "480"];
    delete content.uuid;
    if (content.source === "file") {
      delete content.url;
      delete content.thumbnail;
    }

    if (!content.premium.length || !content.premium) delete content.premium;

    const formData = new FormData();
    const _content = removeEmpty(content);
    for (const [key, value] of Object.entries({ ..._content })) {
      formData.append(key, key === "premium" && value.length ? JSON.stringify(value) : value);
    }

    if (uuid) _content.uuid = uuid;

    if (files.length === 2) {
      files.forEach(file => {
        formData.append('files', file.file)
      })
    }
    return {
      form: formData,
      contents: content
    };
  }

  const createUpdate = () => {

    if (!uuid) {
      sendPostRequest('contents', getContent().form,
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        });
    } else {
      sendPatchRequest(`contents/${uuid}`, getContent().contents,
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        });
    }
  };

  const getPath = (withBucket = true) => withBucket === true ? `s3://${navTrack.join("/")}` : navTrack.slice(1, navTrack.length).join("/")

  const createDirectory = () => {
    sendPostRequest('directories', { name: `${getPath()}/${newFolder}/` },
      (loading) => setStatus({ ...{ loading: true } }), (data) => {
        setDirectories(data.data);
      });
  }


  useEffect(() => {
    if (newFolder.length > 1) {
      setStatus({ ...{ loading: false } })
      handleNavigate(navTrack[navTrack.length - 1], true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directories]);

  const showUrlOps = () => content.source === "s3";

  const autoDetect = () => content.source === "auto";

  const onValueChange = (value, tag) => {

    setContent({ ...content, [tag]: value });
    const error = !(`${value}`.startsWith("s3://") && `${value}`.includes(".") && `${value}`.split(".").length >= 2);
    if (tag === "thumbnail") setThumbError(error);
    if (tag === "url") setUrlError(error);

  };

  const handleDetectData = (event) => {
    event.preventDefault();
    setButtonLabel("");
    sendPostRequest('directories/autodetect', { path: detectUrl },
      (loading) => setOnDetecting(loading), (data) => {
        setButtonLabel("Auto Detect");
        if (data.data) {
          onValueChange("s3", "source");
          setDetectUrl(null);
          setContent(data.data);
        } else {
          setStatus({ ...{ loading: false, message: "Failed to populate data", error: true } });
        }
      });
  }

  const checkPropsValidity = () => {
    let basic = content.title !== null && content.section !== null && content.language !== null &&
      content.fromAge !== null && (+`${content.fromAge}`) >= 0 && content.toAge !== null && +(`${content.toAge}`) <= 100;

    basic = basic && ((+`${content.fromAge}`) < (+`${content.toAge}`));

    const isS3Validsource = (content.source === "s3" && files.length === 0)
      && content.url !== null && content.thumbnail !== null && !thumbError && !urlError;

    const isFileValidSource = files.length === 2 && content.source !== "s3" && content.location !== null;

    const isYoutubeValid = content.forYoutube === null ? true : content.forYoutube !== null && content.forYoutube.channel !== null
      && content.forYoutube.fade !== null;

    basic = basic && (isS3Validsource || isFileValidSource) && isYoutubeValid;

    return basic;
  }

  return (
    <EditPage
      title={uuid ? `Edit content` : `Add content`}
      status={status}
      btnLabel={uuid ? "Update Now" : "Add Now"}
      onSave={() => createUpdate()}
      spacing={5}
      onBack={() => navigate(`${AppRoutes.contents}`, true)}
      validated={checkPropsValidity() &&
        !status.loading &&
        validateYoutubeProps()
      }
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setContent({ ..._ref });
          navigate(`${AppRoutes.contents}`, true);
        }
      }}
    >
      <Grid item xs={2} xl={12}>
        <FormControl sx={{ minWidth: 50 }} fullWidth>
          <InputLabel id="content-source">Content Source</InputLabel>
          <Select
            value={content.source || "s3"}
            label="Content Source"
            labelId="content-source"
            id="source"
            disabled={status.loading}
            onChange={(e) => onValueChange(e.target.value, "source")}
          >
            {[
              { name: "S3 URLS", value: "s3" },
              { name: "Auto detect S3", value: "auto" },
              { name: "File Upload", value: "file" },
            ].map((souces) => {
              return (
                <MenuItem value={souces.value} id={souces.value}>
                  &nbsp;&nbsp;&nbsp;{souces.name}
                  <br />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      {!autoDetect() && (<Grid item xs={5} xl={12}>
        <EasyTextEdit
          label="Content title"
          placeholder="Descriptive tile of this content"
          type="text"
          id="title"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={content.title}
          onChange={onValueChange}
        />
      </Grid>)}

      {autoDetect() && (<Grid item xs={7} xl={12}>
        <EasyTextEdit
          label="S3 content location"
          placeholder="An S3 folder where your content is"
          type="text"
          id="folder"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={detectUrl}
          onChange={(value, tag) => setDetectUrl(value)}
        />
      </Grid>)}

      {autoDetect() && (<Grid item xs={3} xl={12}>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          disabled={onDetecting || !(`${detectUrl}`.length > 0 && `${detectUrl}`.startsWith("s3://"))}
          color="secondary"
          onClick={handleDetectData}
          className={appStyle.button}
        >
          {onDetecting && <CircularProgress color="primary" />}
          {btnLabel}
        </Button>
      </Grid>)}

      {!autoDetect() && (<Grid item xs={5} xl={12}>
        <FormControl sx={{ minWidth: 50 }} fullWidth>
          <InputLabel id="subs">Content subtitle</InputLabel>
          <Select
            value={content.subTitle}
            label="Content subtitle"
            labelId="subs"
            id="subTitle"
            disabled={status.loading}
            onChange={(e) =>
              onValueChange(e.target.value,
                "subTitle"
              )
            }
          >
            {["Akili and Me", "Ubongo Kids", "Nuzo and Namia", "Caregivers"].map((sub) => {
              return (
                <MenuItem value={sub}>
                  &nbsp;&nbsp;&nbsp;{sub}
                  <br />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>)}


      {(showUrlOps() && !autoDetect()) && (
        <Grid item xs={6} xl={12}>
          <EasyTextEdit
            placeholder="S3 file URI i.e s3://bucket-id/folder/directory/image.jpg"
            label="Content thumbnail image"
            type="text"
            textField={true}
            error={thumbError}
            id="thumbnail"
            errorText={
              thumbError ? "Make sure you fill in AWS s3 file URI" : ""
            }
            disabled={status.loading}
            value={content.thumbnail}
            onChange={onValueChange}
          />
        </Grid>
      )}

      {showUrlOps() && !autoDetect() && (
        <Grid item xs={6} xl={12}>
          <EasyTextEdit
            placeholder="S3 file URI i.e s3://bucket-id/folder/directory/video.mp4"
            label="Actual content url (Image, Video, Audio, PDF)"
            type="text"
            textField={true}
            error={urlError}
            id="url"
            errorText={urlError ? "Make sure you fill in AWS s3 file URI" : ""}
            disabled={status.loading}
            value={content.url}
            onChange={onValueChange}
          />
        </Grid>
      )}

      {content.source === "file" && getMimeType().length >= 2 && !autoDetect() && (
        <Grid item xs={12} xl={12}>
          <Dropzone
            onChange={updateFiles}
            value={files}
            minHeight="195px"
            maxFiles={2}
            accept={getMimeType().join(",")}
            maxFileSize={1024 * 1024 * 1024 * 5}
            label="Drag'n drop thumbnail and content here or click to browse"
          >
            {files.map((file) => (
              <FileMosaic
                {...file}
                resultOnTooltip
                alwaysActive
                preview
                info
                onDelete={onDelete}
                onSee={handleSee}
                onWatch={handleWatch}
              />
            ))}
          </Dropzone>

          <FullScreen
            open={imageSrc !== undefined}
            onClose={() => setImageSrc(undefined)}
          >
            <ImagePreview src={imageSrc} />
          </FullScreen>
          <FullScreen
            open={videoSrc !== undefined}
            onClose={() => setVideoSrc(undefined)}
          >
            <VideoPreview src={videoSrc} autoPlay controls />
          </FullScreen>
        </Grid>
      )}

      {!autoDetect() && (<Grid item xs={4} xl={12}>
        <EasyTextEdit
          placeholder="Ordering preference"
          label="Order"
          type="text"
          id="order"
          textField={true}
          errorText=""
          disabled={status.loading}
          value={content.order}
          onChange={onValueChange}
        />
      </Grid>)}

      {!autoDetect() && (<Grid item xs={4} xl={12}>
        <FormControl sx={{ minWidth: 200 }} fullWidth>
          <InputLabel id="type-label">Content type</InputLabel>
          <Select
            value={content.category}
            label="Content Type"
            labelId="type-label"
            id="category"
            autoWidth
            disabled={status.loading}
            onChange={(e) => {
              onValueChange(e.target.value, "category");
            }}
          >
            {(categories || []).map((category) => {
              return (
                <MenuItem value={category.uuid} id={category.uuid}>
                  &nbsp;&nbsp;&nbsp;{category.name}
                  <br />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>)}

      {content.category && !autoDetect() && (
        <Grid item xs={4} xl={12}>
          <FormControl
            disabled={status.loading}
            sx={{ minWidth: 200 }} fullWidth>
            <InputLabel id="section-label">Content Section</InputLabel>
            <Select
              value={content.section}
              label="Content Section"
              labelId="section-label"
              id="section"
              onChange={(e) => onValueChange(e.target.value, "section")}
            >
              {(sections || [])
                .filter((section) => section.category.uuid === content.category)
                .map((section) => {
                  return (
                    <MenuItem value={section.uuid} id={section.uuid}>
                      &nbsp;&nbsp;&nbsp;{section.title}
                      <br />
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      )}

      {!autoDetect() && (<Grid item xs={12}>
        <Typography variant="h6" component="h2">
          Langauges, Audiance and Paywall
        </Typography>
      </Grid>)}


      {!autoDetect() && (<Grid item xs={2} xl={12}>
        <FormControl
          disabled={status.loading}
          sx={{ minWidth: 50 }} fullWidth>
          <InputLabel id="language">Content Language</InputLabel>
          <Select
            value={content.language}
            label="Content Language"
            labelId="language"
            id="language"
            onChange={(e) => onValueChange(e.target.value, "language")}
          >
            {languages.map((language) => {
              return (
                <MenuItem value={language.isoCode} id={language.isoCode}>
                  &nbsp;&nbsp;&nbsp;{language.name}
                  <br />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>)}

      {!autoDetect() && (<Grid item xs={6} xl={12}>
        <Autocomplete
          multiple={true}
          id="age-group"
          disableCloseOnSelect
          limitTags={2}
          disabled={status.loading}
          value={ageRange}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="filled" label={ageGroup.filter(group => group.range === option)[0].name} {...getTagProps({ index })} />
            ))
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {ageGroup.filter(group => group.range === option)[0].name}
            </li>
          )}
          options={ageGroup.map((option) => option.range)}
          renderInput={(params) => (
            <TextField {...params} label="Target audiance" />
          )}
          onChange={(event, value) => {
            setAgeRange(value);
            const ranges = value.length ? getSingleRange(value).split('-') : [null, null];
            setContent({ ...content, fromAge: ranges[0], toAge: ranges[1] })
          }}
        />
      </Grid>)}

      {!autoDetect() && (<Grid item xs={4} xl={12}>
        <Autocomplete
          multiple
          id="premium"
          disableCloseOnSelect
          disabled={status.loading}
          limitTags={2}
          value={content.premium && Array.isArray(content.premium) ? content.premium.map(pre => pre.name) : []}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          options={continents.map((option) => option.name)}
          renderInput={(params) => (
            <TextField {...params} label="Premium Content Locations" />
          )}
          onChange={(event, value) => {
            const values = continents
              .filter(cont => value.includes(cont.name))
            onValueChange(values, "premium");
          }}
        />
      </Grid>)}


      {!autoDetect() && (<Grid item xs={12}>
        <Typography variant="h6" component="h2">
          Destination{isVideo() ? ", Streaming qualities & Trademarking" : " "}
        </Typography>
      </Grid>)}

      {!autoDetect() && (<Grid item xs={noBranding() ? isVideo() ? 5 : 8 : 4} xl={12}>
        <EasyTextEdit
          label={`Save output to (${content.source === "file" ? "Mandatory" : "Optional"})`}
          placeholder="New location when your content will be available"
          type="text"
          id="location"
          errorText=""
          error={false}
          textField={true}
          value={content.location}
          onChange={(value, field) => null}
          disabled={status.loading}
          endAdornment={<InputAdornment
            onClick={() => {
              if (!status.loading) {
                handleNavigate("/", false);
                startBrowse(true);
              }

            }}
            position="end"
          >
            <IconButton edge="center">
              <Icon
                children={"folder_open"}
                className={appStyle.input_icons}
              />
            </IconButton>
          </InputAdornment>}
          startAdornment={content.location && (<InputAdornment
            onClick={() => onValueChange(undefined, "location")}
            position="start"
          >
            <IconButton edge="center">
              <Icon
                children={"delete"}
                className={appStyle.input_icons}
              />
            </IconButton>
          </InputAdornment>)}
        />
      </Grid>)}
      {!autoDetect() && isVideo() && (<Grid item xs={noBranding() ? 4 : 3} xl={12}>
        <Autocomplete
          multiple
          id="qualities"
          disableCloseOnSelect
          limitTags={noBranding() ? 2 : 1}
          value={content.streamQuality}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="filled" label={`${option}p`} {...getTagProps({ index })} />
            ))
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {`${option}p - ${option === "2160" ? "4K" : option === "1080" ? "UHD" : option === "720" ? "HD" : "SD"}`}
            </li>
          )}
          options={["240", "360", "480", "720", "1080", "2160"]}
          renderInput={(params) => (
            <TextField {...params} label={`Streaming quality`} />
          )}
          onChange={(event, value) => {
            onValueChange(value, "streamQuality");
          }}
        />
      </Grid>)}
      {!autoDetect() && isVideo() && (<Grid item xs={noBranding() ? 3 : 3} xl={12}>
        <FormControl
          disabled={status.loading}
          sx={{ minWidth: 50 }} fullWidth error={checkLogoChennelError()}>
          <InputLabel id="show">Branding from</InputLabel>
          <Select
            value={noBranding() ? "none" : content.logoRef}
            label="Branding from"
            labelId="show"
            id="logoRef"
            onChange={(e) => {
              onValueChange(e.target.value, "logoRef")
            }}
          >
            {[{ uuid: "none", name: "No-Branding" }, ...tvShows,].map((tv) => {
              return (
                <MenuItem value={tv.uuid} id={`tv-${tv.uuid}`}>
                  &nbsp;&nbsp;&nbsp;{tv.name}
                  <br />
                </MenuItem>
              );
            })}
          </Select>
          {checkLogoChennelError() && content.logoRef !== content.forYoutube.channel && (<FormHelperText >TV show and Channel must be the same</FormHelperText>)}
        </FormControl>
      </Grid>)}

      {!autoDetect() && !noBranding() && isVideo() && (<Grid item sx={{ pt: 1 }} xs={noBranding() ? 1 : 2} xl={12}>
        <FormControlLabel
          checked={content.withIntroOutro ?? false}
          control={<Switch />}
          disabled={status.loading}
          onChange={(event) => onValueChange(event.target.checked, "withIntroOutro")}
          label={<Typography variant="body1">Intro/Outro</Typography>}
        />
      </Grid>)}

      {!autoDetect() && isVideo() && (<Grid item xs={12}>
        <Typography variant="h6" component="h2">
          Uploading options
        </Typography>
      </Grid>)}

      {!autoDetect() && (<Grid item xs={6} xl={12}>
        <FormControlLabel
          checked={content.forTheApp}
          control={<Switch />}
          disabled={status.loading}
          onChange={(event) => {
            onValueChange(event.target.checked, "forTheApp");
            setSchedule(false);
          }}
          label={<Typography variant="body1">Upload to the app</Typography>}
        />

        {content.forTheApp && (
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={6} xl={12}>
              <FormControlLabel
                checked={schedule}
                disabled={status.loading}
                control={<Switch />}
                onChange={(event) => {
                  setSchedule(event.target.checked);
                  if (!event.target.checked) onValueChange("", "scheduledAt");
                }}
                label={<Typography variant="body1">Schedule content</Typography>}
              />
            </Grid>
            {schedule && (<Grid item xs={6} xl={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDateTimePicker
                  label={"Goes live at"}
                  ampm={false}
                  value={date}
                  disabled={status.loading}
                  renderInput={(props) => <TextField
                    {...props}
                    size='large'
                    variant="outlined"
                    className={appStyle.inputs}
                    helperText={null} />}
                  onChange={(value) => {
                    onValueChange(toTzTime(value.toDate()).format(), "scheduledAt");
                    setDate(value.toDate());
                  }}
                />
              </LocalizationProvider>
            </Grid>)}
          </Grid>
        )}
      </Grid>)}


      {!autoDetect() && isVideo() && (<Grid item xs={6} xl={12}>
        <FormControlLabel
          checked={content.forYoutube !== null}
          control={<Switch />}
          disabled={status.loading}
          onChange={(event) => {
            onValueChange(
              event.target.checked ? { uploaded: false } : null,
              "forYoutube"
            );
          }}
          label={
            <Typography variant="body1">Upload to Youtube</Typography>
          }
        />

        {content.forYoutube !== null && (
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={6} xl={12}>
              <FormControl
                disabled={status.loading}
                sx={{ minWidth: 50 }} fullWidth>
                <InputLabel id="channels">Channels</InputLabel>
                <Select
                  value={content.forYoutube.channel}
                  label="Channel"
                  labelId="channels"
                  id="channels"
                  onChange={(e) =>
                    onValueChange(
                      {
                        ...content.forYoutube,
                        channel: e.target.value,
                      },
                      "forYoutube"
                    )
                  }
                >
                  {channels.map((channel) => {
                    return (
                      <MenuItem value={channel.uuid} id={channel.uuid}>
                        &nbsp;&nbsp;&nbsp;{channel.name}
                        <br />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} xl={12}>
              <FormControl
                disabled={status.loading}
                sx={{ minWidth: 50 }} fullWidth>
                <InputLabel id="fades">Transition Fade</InputLabel>
                <Select
                  value={content.forYoutube.fade}
                  label="Transition Fade"
                  labelId="fades"
                  id="fades"
                  onChange={(e) =>
                    onValueChange(
                      {
                        ...content.forYoutube,
                        fade: e.target.value,
                      },
                      "forYoutube"
                    )
                  }
                >
                  {[true, false].map((fade) => {
                    return (
                      <MenuItem value={fade} id={fade}>
                        &nbsp;&nbsp;&nbsp;{fade ? "White fade" : "Black fade"}
                        <br />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Grid>)}

      <Dialog
        open={browse}
        onClose={() => startBrowse(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle> {isBucketSeletions() ? `Select a source to save from` : `Where do you want to save your files`}</DialogTitle>
        <DialogContent>
          <Grid xs={12} xl={12}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {["/", ...navTrack].map((nav, index) => (
                <Link
                  underline="hover"
                  key={`${index + 1}`}
                  color="inherit" href="/"
                  onClick={(event) => {
                    event.preventDefault();
                    handleNavigate(nav, true);
                  }}>
                  {getDisplayFolderName(nav)}
                </Link>
              ))}
            </Breadcrumbs>
          </Grid>
          <Grid container direction="row">

            {(actives || []).length > 0 && (actives.map(dir => {
              const full = directories[dir] && directories[dir].length > 0;
              return (<Grid sx={{ mt: 4 }} spacing={isBucketSeletions() ? (actives.length === 1 ? 12 : 6) : 3} xs={isBucketSeletions() ? 6 : 4} xl={12} className={appStyle.folder}>
                
                <HtmlTooltip title={getFolderName(dir)}>
                  <IconButton
                    edge="center"
                    onClick={() => {
                      handleNavigate(getFolderName(dir, false), false);
                    }}
                  >
                    <Icon
                      children={isBucketSeletions() ? "source" : full ? "folder" : "folder_open"}
                      className={full ? appStyle.folder_icons_can : appStyle.folder_icons}
                    />
                  </IconButton>
                <Typography
                  color="text.primary" align="center" mt={1}>{getDisplayFolderName(getFolderName(dir))}</Typography>
                {full && (<Typography
                  color="text.disabled" variant="caption" align="center" >{`${directories[dir].length} sub director${directories[dir].length === 1 ? "y" : "ies"}`}</Typography>)}
                </HtmlTooltip>
                </Grid>)
            }))}
          </Grid>

          {!isBucketSeletions() && (<Grid container sx={{ pt: 8, mb: 4 }} direction="row">
            <Grid item xs={7} xl={12} >
              <EasyTextEdit
                label="New directory name"
                placeholder="Content's directory name"
                type="text"
                id="directory"
                errorText=""
                textField={true}
                disabled={status.loading}
                value={newFolder}
                onChange={(value) => setNewFolder(value)}
              />
            </Grid>
            <Grid item xs={4} xl={12} justifyContent="center">  <Button
              variant="contained"
              className={appStyle.createDirectory}
              sx={{ ml: 2 }}
              fullWidth
              disabled={status.loading || newFolder.length <= 0}
              onClick={(event) => createDirectory()}
            >{status.loading && <CircularProgress color="primary" />}{status.loading ? "" : "Create directory"}</Button> </Grid>

          </Grid>)}
        </DialogContent>
        <DialogActions sx={{ pb: 4 }}>
          <Button sx={{ mr: 2, pl: 2, pr: 2 }} onClick={() => {
            handleNavigate("/", false);
            startBrowse(false);
          }}>Cancel</Button>
          {!isBucketSeletions() && (<Button sx={{ mr: 2, pl: 2, pr: 2 }} onClick={() => {
            onValueChange(getPath(false), "location")
            startBrowse(false);
          }}>Select</Button>)}

        </DialogActions>
      </Dialog>
    </EditPage>
  );
};

export default ContentEdit;