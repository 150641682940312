import { Grid} from "@mui/material";
import ReactPlayer from 'react-player'
//import ReactHlsPlayer from 'react-hls-player';
import {
  getParams,
  guardRoute,
  navigate,
  sendGetRequest
} from "../utils/utils";
import EditPage from "./component/edit-page";
import 'shaka-react-video-player/dist/controls.css';
import 'shaka-react-video-player/dist/themes.css';
import ShakaPlayer from "shaka-react-video-player";
import { useEffect, useState } from "react";


const LinkPlayer = () => {
  guardRoute(false, true);
  const { uuid, type, source} = getParams();
  const isStream = type === "stream";
  const title = `${isStream ? "Streaming content now" : "Play content"}`;
  var [urls, setUrls] = useState({stream: null, url: null});
  const [status, setStatus] = useState({
    error: false,
    loading: true,
    message: null,
  });

  const executeFetch = () => {
    sendGetRequest(`contents?uuid=${uuid}`, (loading) => setStatus({ ...status, loading }),
      (data) => setUrls({ ...urls, ...data.urls}));
  };

  useEffect(
    () => executeFetch(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uuid]
  );

  return (
    <EditPage
      title={title}
      status={{}}
      btnLabel={""}
      onSave={() => {}}
      validated={false}
      backgroundColor={"#000000"}
      onFinish={() => {}}
      removeTopPadding={true}
      onBack={() => navigate(source, true)}
    >

      {isStream && urls.stream && status.loading === false && (<Grid item xs={12} xl={12}>
        <ShakaPlayer
          src={urls.stream}
          autoPlay
          width="100%"
          height="auto"
          hlsConfig={{
            maxLoadingDelay: 4,
            minAutoBitrate: 0,
            lowLatencyMode: true,
          }}
        />
      </Grid>)}

      {!isStream && urls.url  && status.loading === false && (<Grid item xs={12} xl={12}>
        <ReactPlayer url={urls.url} controls={true} width={"100%"} muted={true} height={500}/>
      </Grid>)}
    </EditPage>
  );
};

export default LinkPlayer;