import {
  guardRoute,
  watchLiveData,
  getStatus,
  sendGetRequest,
  formatDate,
  sendPatchRequest,
  sendDeleteRequest,
  navigate,
  getStreamingReport,
} from "../utils/utils";
import {
  Icon,
  DialogContent,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  ListItemButton
} from "@mui/material";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import { coreStyles } from "../theme/style";
import DataListWithSearch from "./component/@list/list-search";

const getTableHead = () => {
  return [
    { id: "createdAt", label: "Logged on", align: 'left' },
    { id: "title", label: "Content title", align: 'left' },
    { id: "name", label: "Belongs to", align: 'left' },
    { id: "stream", label: "Streams", align: 'center' },
    { id: "link", label: "Link", align: 'center' },
    { id: "status", label: "Status", align: 'left' },
    { id: "_", alignRight: false },
  ];
};

const TaskList = () => {
  guardRoute();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);
  const [report, setReport] = useState({});
  const appStyle = coreStyles();

  const actionOptions = [
    {
      icon: "replay",
      value: "re-try",
      label: "Re-try",
      onClick: (value, selected) => {
        const _selected = dataList.filter(data => selected.includes(data.uuid) && data.status !== "COMPLETE").map(data => data.uuid);
        Promise.all(
          _selected.map((uuid) => sendPatchRequest(`tasks/${uuid}/retry`, {},
            loading => onLoading(loading), data => { }))
        ).then((response) => executeFetch(true));
      }
    },
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) => {
        Promise.all(
          selected.map((uuid) => sendDeleteRequest(`tasks`, selected.join(","),
            loading => onLoading(loading), data => { }))
        ).then((response) => executeFetch(true));
      }
    }
  ];
  const filterOptions = [];

  const executeFetch = (refresh = false) => {
    sendGetRequest('tasks?metadata=true', (loading) => onLoading(refresh ? false : loading), (data) => setDataList(data));
  };

  useEffect(() => {
    executeFetch();
    watchLiveData("tasks", (data) => executeFetch(true));
    // eslint-disable-next-line
  }, []);

  const handleClickLink = (uuid, type) => {
    navigate(`${AppRoutes.tasks}/${AppRoutes.plays}?uuid=${uuid}&type=${type}&source=${AppRoutes.tasks}`, true)
  }

  return (
    <div>
      <DataListWithSearch
        sectionTitle="Tasks history"
        createNewLabel={"Transcoding reports"}
        search={false}
        createNewIcon="bar_chart"
        data={dataList}
        primaryKey="uuid"
        orderColumn="createdAt"
        emptyIcon="task_alt"
        avatarKey="uuid"
        loading={loading}
        onCreateNewItem={() => {
          getStreamingReport(loading => onLoading(loading), data => {
            setReport(data);
          });
        }}
        headers={getTableHead()}
        rowData={(data) => {
          const _entries = [
            { value: formatDate(data.createdAt, "hh:mm A  MMM DD, YYYY"), width: 190 },
            { value: data.content.title, width: 200 },
            { value: data.content.section && data.content.section.title ? data.content.section.title : data.channel ? data.channel.name : "N/A", width: 130 },
            {
              value:
                data.streamId && data.status === "COMPLETE" ? (<Icon children={"stream"} color={"error"} className={appStyle.asLink} onClick={() => handleClickLink(data.content.uuid, "stream")} />) : (
                  `  -  `
                ),
              align: "center",
            }, {
              value:
                data.youtubeId && data.status === "COMPLETE" ? (<Icon children={"play_circle_filled"} color={"primary"} className={appStyle.asLink} onClick={() => handleClickLink(`https://www.youtube.com/watch?v=${data.youtubeId}`, "watch")} />) : (
                  `  -  `
                ),
              align: "center",
            },
            { ...getStatus(data.status), width: 80 },
          ];
          return {
            exclude: [
              data.status !== "COMPLETE" ? "" : "re-try",
              data.status === "COMPLETE" ? "" : "",
            ],
            entries: _entries,
          };
        }}
        selectionOptions={actionOptions}
        filterOptions={filterOptions}
        optionItems={actionOptions}
      />

      <Dialog
        open={Object.keys(report).length > 0}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ pt: 4 }}> Stream URLs report </DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>

            <List>
              <ListItem>
                <ListItemButton>
                  <Icon children={"attach_money"} sx={{ mr: 2 }} />
                  <ListItemText primary="Total Cost" sx={{ mr: 8 }} />
                  <ListItemText primary={report.totalCost} />
                </ListItemButton>
              </ListItem>

              <ListItem>
                <ListItemButton>
                  <Icon children={"payment"} sx={{ mr: 2 }} />
                  <ListItemText primary="Paid so far" sx={{ mr: 4 }} />
                  <ListItemText primary={report.paidSoFar} />
                </ListItemButton>
              </ListItem>

              <ListItem>
                <ListItemButton>
                  <Icon children={"account_balance_wallet"} sx={{ mr: 2 }} />
                  <ListItemText primary="Unpaid" sx={{ mr: 10 }} />
                  <ListItemText primary={report.balance} />
                </ListItemButton>
              </ListItem>

              <ListItem>
                <ListItemButton >
                  <Icon children={"hd"} sx={{ mr: 2 }} />
                  <ListItemText primary="Qualities" sx={{ mr: 10 }} />
                  <ListItemText primary={`${report.qualities || ""}`.split(",").map(quality => `${quality}p`).join(" , ")} />
                </ListItemButton>
              </ListItem>

              <ListItem>
                <ListItemButton >
                  <Icon children={"video_library"} sx={{ mr: 2 }} />
                  <ListItemText primary="Processed videos" />
                  <ListItemText primary={report.totalVideoProcessed} />
                </ListItemButton>
              </ListItem>

              <ListItem>
                <ListItemButton>
                  <Icon children={"query_builder"} sx={{ mr: 2 }} />
                  <ListItemText primary="Processed minutes" sx={{ mr: 4 }} />
                  <ListItemText primary={report.minutes} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </DialogContent>
        <DialogActions sx={{ pb: 4, pr: 4 }}>
          <Button onClick={() => {
            setReport({})
          }}>Dismiss</Button>

        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TaskList;
